import React from "react";
import "../styles/header.css";

const Header = () => {
  return (
    <div className="container">
      <div className="images">
        <img alt="" src="./static/logo.png" />
      </div>
      <div className="images">
        <img alt="" src="./static/logo-aldea.png" />
      </div>
      <div className="separator"></div>
      <h2 className="title">Generador de link</h2>
    </div>
  );
};

export default Header;
