import React from "react";
import '../styles/alert.css';

const Alert = () => {
  return (
    <div className="container-alert">
      <div className="barra"></div>
      <h2 className="info-text">
        A continuación podrás copiar el link que debes enviar a los miembros de
        tu equipo. Previamente, necesitamos que nos indiques tu dirección de
        mail. Es importante que el mail sea del hosting de Google (Gmail).
      </h2>
    </div>
  );
};

export default Alert;
