import React from "react";
import "../styles/footer.css";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="barra-footer"></div>
      <p className="info-footer">
        Comparte este link para que los miembros de tu equipo completen el
        formulario. Luego podrás ver la gráfica con sus respuestas.
      </p>
    </div>
  );
};

export default Footer;
