import React, { useState } from "react";
import "../../styles/generator.css";

const GeneratorOmega = () => {
    const [gmail, setGmail] = useState({
        mail: "",
    });
    const [viewMail, setViewMail] = useState(false);
    const [isCopy, setIsCopy] = useState(false);
    const handleMail = (e) => {
        setGmail({ ...gmail, [e.target.name]: e.target.value });
    };

    const handleCopy = () => {
        setIsCopy(false);
        if (gmail.mail !== "") {
            navigator.clipboard.writeText(
                `https://docs.google.com/forms/d/e/1FAIpQLSeJuZLQQVK9Jg0mYVFTeVSv2N5RiDzGiToM0SlYgnMvTCCnoQ/viewform?usp=pp_url&entry.1799182697=${gmail.mail}`
            );
            setIsCopy(true);
        }
    };

    return (
        <div className="container-generator">
            <div className="wrapper-input">
                <label>Ingresa tu Gmail</label>
                <input
                    type="email"
                    name="mail"
                    value={gmail.mail}
                    onChange={handleMail}
                    placeholder="ejemplo@gmail.com"
                    className="input"
                />
            </div>
            <div className="buttons">
                <button
                    className="button"
                    onClick={() => setViewMail(!viewMail)}
                    type="button"
                >
                    Generar Link
                </button>
                {viewMail ? (
                    gmail.mail ? (
                        <button className="copy-button" onClick={handleCopy}>
                            ¡Copiar!
                        </button>
                    ) : null
                ) : null}
            </div>

            {viewMail ? (
                gmail.mail !== "" ? (
                    <div className="wrapper-link">
                        <p className="info-link">
                            {`https://docs.google.com/forms/d/e/1FAIpQLSeJuZLQQVK9Jg0mYVFTeVSv2N5RiDzGiToM0SlYgnMvTCCnoQ/viewform?usp=pp_url&entry.1799182697=${gmail.mail}`}
                        </p>
                    </div>
                ) : null
            ) : null}
        </div>
    );
};

export default GeneratorOmega;
