import React from 'react'
import {
    Routes,
    Route
} from "react-router-dom";
import { useNavigate  } from "react-router-dom";
import Alert from './components/Alert';
import Footer from './components/Footer';
import Generator from './components/Generator';
import Header from './components/Header';
import GeneratorOmega from './components/omega/Generador';
import HeaderOmega from './components/omega/Header';

const RoutesApp = () => {

    return (
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/ultima-aldea" element={<UltimaAldea/>} />
            <Route path="/omega-packaging" element={<Omega/>} />
            {/* <Route exact path="/nuevo">
                <h1>Nuevo</h1>
            </Route> */}
        </Routes>
    )
}

export default RoutesApp


const UltimaAldea = () => {
    return <>
        <Header />
        <div className="wrapper">
            <h2 className='text-center'>Última Aldea</h2>
            <Alert />
            <Generator />
            <Footer />
        </div>
    </>
}

const Omega = () => {
    return <>
        <HeaderOmega/>
        <div className="wrapper">
            <h2 className='text-center'>Omega Packaging</h2>
            <Alert />
            <GeneratorOmega />
            <Footer />
        </div>
    </>
}

const Home = () => {

    const history = useNavigate()

    return <div className='flex-home'>
        <div onClick={() => history('/omega-packaging')} className='btn-nav'>
            <p>Omega Packaging</p>
            <img alt="" src="./static/logo-omega.png" />
        </div>
        <div onClick={() => history('/ultima-aldea')} className='btn-nav'>
            <p>Ultima Aldea</p>
            <img alt="" src="./static/logo-aldea.png" />
        </div>
    </div>
}